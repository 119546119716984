import {defineStore} from 'pinia';
import {SnackbarType} from '~/service/SnackbarService/SnackbarType';
import {wait} from "~/service/Utils";

export type SnackbarLocation = 'top' | 'bottom';

type SnackbarMessage = {
  message: string;
  type: SnackbarType;
  location: SnackbarLocation;
}

const SNACKBAR_DURATION = 5_000 as const;

export default function useSnackbarStore() {
    const messages = useState<SnackbarMessage[]>('snackbars', () => []);
    const addMessage = (message: SnackbarMessage) => {
        messages.value.push(message);
        setTimeout(() => {
            const indexToDelete = messages.value.findIndex((msg) => msg === message);
            messages.value.splice(indexToDelete, 1);
        }, SNACKBAR_DURATION);
    };
    return {
        messages,
        addMessage,
    };
}
